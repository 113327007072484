import axios from 'axios'
const url = 'https://maestrix.grupoparque.com.br:8443/api-consulta-ivertex/apiopen/'
//const url = 'http://localhost:8090/apiopen/'


export const httpopen = axios.create({
     baseURL: url, 
})

export default {
     url,
}